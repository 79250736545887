/* eslint-disable max-classes-per-file */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */

import React, { memo, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { select } from 'd3-selection';
import * as simulation from 'd3-force';
import { drag } from 'd3-drag';

import InputSlider from '../styles/InputSlider';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';

import { Grid, columns } from '../styles/grid';
import ToggleSwitch from '../styles/toggleSwitch';

import FlowLogo from '../assets/flow-logo.svg';
import Excel from '../assets/softwares/excel.png';
import Catia from '../assets/softwares/catia.png';
import Word from '../assets/softwares/word.png';
import Matlab from '../assets/softwares/matlab.png';
import StarCcm from '../assets/softwares/star-ccm.png';
import TeamCenter from '../assets/softwares/team-center.png';

import { lerp, randomInt } from '../utils/math';

const SPEED = 0.005;

const Container = styled(Grid)`
  position: relative;
  margin-top: 74px;
`;

const Graph = styled.div`
  ${columns(1, 12)}
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const Options = styled.div`
  ${TYPO.p1}
  position: relative;
  display: flex;
  margin-bottom: 12px;
  & span {
    font-weight: 500;
    margin-left: 6px;
  }
`;

const SvgWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const SVG = styled.svg`
  width: 100%;
  max-width: 1200px;
  min-width: 860px;
  margin-top: 12px;
  & .links line {
    stroke: ${COLORS.shades.s200.css};
  }
  & .nodes circle {
    fill: ${COLORS.brand.regular.css};
  }
  & .softwares image {
    opacity: 1;
    transition: opacity 0.2s ease;
  }
  & .softwares.active image {
    opacity: 0;
  }
  & .flow {
    opacity: 0;
    transform: scale(0);
    transform-origin: 50%;
    transition: all 0.2s ease;
  }
  & .flow.active {
    transform: scale(1);
    opacity: 1;
  }
  & text tspan {
    ${TYPO.caption}
    pointer-events: none;
    &::selection {
      background: transparent;
    }
  }
  & .fade {
    width: 100%;
    height: 30px;
    pointer-events: none;
  }
`;

enum NodeType {
  ENGINEER = 'engineer',
  SOFTWARE = 'software',
  FLOW = 'flow',
}

const engineers = [
  {
    id: 'a',
    name: 'Lead Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'word', 'teamcenter'],
    order: 0,
  },
  {
    id: 'b',
    name: 'Aero Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'matlab', 'star'],
    order: 0,
  },
  {
    id: 'c',
    name: 'Mechanical Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'catia'],
    order: 1,
  },
  {
    id: 'd',
    name: 'Thermal Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'word', 'matlab'],
    order: 2,
  },
  {
    id: 'e',
    name: 'Aero Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'matlab', 'star'],
    order: 3,
  },
  {
    id: 'f',
    name: 'Mechanical Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'catia'],
    order: 4,
  },
  {
    id: 'g',
    name: 'Thermal Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'word', 'matlab'],
    order: 5,
  },
  {
    id: 'h',
    name: 'Mechanical Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'catia'],
    order: 6,
  },
  {
    id: 'j',
    name: 'Mechanical Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'catia'],
    order: 7,
  },
  {
    id: 'k',
    name: 'Thermal Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'word', 'matlab'],
    order: 8,
  },
  {
    id: 'l',
    name: 'Mechanical Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'catia'],
    order: 9,
  },
  {
    id: 'm',
    name: 'Mechanical Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'catia'],
    order: 10,
  },
  {
    id: 'n',
    name: 'Thermal Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'word', 'matlab'],
    order: 11,
  },
  {
    id: 'o',
    name: 'Mechanical Engineer',
    type: NodeType.ENGINEER,
    softwares: ['excel', 'catia'],
    order: 12,
  },
];

const softwareList = [
  {
    key: 'excel',
    name: 'Microsoft Excel',
    type: NodeType.SOFTWARE,
    image: Excel.src,
  },
  {
    key: 'word',
    name: 'Microsoft Word',
    type: NodeType.SOFTWARE,
    image: Word.src,
  },
  { key: 'matlab', name: 'Matlab', type: NodeType.SOFTWARE, image: Matlab.src },
  { key: 'catia', name: 'Catia', type: NodeType.SOFTWARE, image: Catia.src },
  {
    key: 'star',
    name: 'Star CCM+',
    type: NodeType.SOFTWARE,
    image: StarCcm.src,
  },
  {
    key: 'teamcenter',
    name: 'Team Center',
    type: 'software',
    image: TeamCenter.src,
  },
];

type Node = {
  id: string;
  name: string;
  type: string;
  softwares: string[];
  order: number;
  image?: string;
  key?: string;
};

type Link = { source: string; target: string };

type Data = {
  nodes: Node[];
  links: Link[];
};

interface NodeChart extends Node {
  index?: number;
  vx?: number;
  vy?: number;
  x?: number;
  y?: number;
  fx?: number;
  fy?: number;
}

type LinkChart = { source: NodeChart; target: NodeChart };

const getNewData = (numPeople: number, flowView: boolean): Data => {
  const newData = { nodes: [], links: [] };

  // add people
  const people = engineers.slice(0, numPeople);
  const softwares = [];
  const links = [];
  const linksFlow = [];
  const softwaresFlow = [];
  for (let s = 0; s < people.length; s += 1) {
    for (let t = s; t < people.length; t += 1) {
      if (s !== t) {
        // create links between engineers
        links.push({ source: people[s].id, target: people[t].id });
      }
    }

    for (let p = 0; p < people[s].softwares.length; p += 1) {
      // craete dynamic links engineer-software
      const clone = JSON.parse(JSON.stringify(softwareList));
      const thisSoftware = clone.find((e) => e.key === people[s].softwares[p]);
      thisSoftware.id = `${people[s].id}-${thisSoftware.key}`;
      softwares.push(thisSoftware);
      links.push({ source: people[s].id, target: thisSoftware.id });
    }
    // link all engineers to flow
    linksFlow.push({ source: 'flow', target: people[s].id });
  }

  for (let s = 0; s < softwareList.length; s += 1) {
    const thisSoftware = JSON.parse(JSON.stringify(softwareList[s]));
    thisSoftware.id = `flow-${thisSoftware.key}`;
    thisSoftware.order = s;
    softwaresFlow.push(thisSoftware);
    linksFlow.push({ source: 'flow', target: thisSoftware.id });
  }

  newData.nodes = flowView
    ? [
        ...people,
        ...softwaresFlow,
        { id: 'flow', name: 'flow', type: NodeType.FLOW },
      ]
    : [...softwares, ...people];

  newData.links = flowView ? [...linksFlow] : [...links];

  return newData;
};

class Message {
  network: any;

  element: any;

  time: number;

  reverse: boolean;

  line: any;

  constructor(network) {
    this.network = network;
    this.element = network.groups.messages
      .append('circle')
      .attr('r', this.network.size.message.radius)
      .attr('fill', COLORS.shades.s200.css);
    this.time = Math.random();
    this.tick = this.tick.bind(this);
    this.reset = this.reset.bind(this);
    this.reset();
    this.reverse = Math.random() < 0.5;
  }

  reset() {
    const nodes = this.network.groups.link.selectAll('line').nodes();

    this.line = nodes[randomInt(0, nodes.length - 1)];
    this.time = Math.random();
    this.reverse = Math.random() < 0.5;
  }

  tick() {
    this.time += SPEED;
    if (this.time >= 1) {
      this.reset();
    }
    // visual update logic
    if (this.line) {
      const x1 = this.line.getAttribute(this.reverse ? 'x1' : 'x2');
      const x2 = this.line.getAttribute(this.reverse ? 'x2' : 'x1');
      const y1 = this.line.getAttribute(this.reverse ? 'y1' : 'y2');
      const y2 = this.line.getAttribute(this.reverse ? 'y2' : 'y1');

      this.element
        .attr('cx', lerp(x1, x2, this.time))
        .attr('cy', lerp(y1, y2, this.time));
    }
  }
}

class Chart {
  size: {
    width: number;
    height: number;
    flow: {
      width: number;
      height: number;
      logo: number;
      sideGap: number;
    };
    engineer: {
      radius: number;
      position: number;
    };
    software: {
      width: number;
      position: number;
    };
    message: {
      radius: number;
      count: number;
    };
  };

  element: any;

  svg: any;

  groups: {
    link: any;
    messages: any;
    node: any;
    softwares: any;
    text: any;
    flow: any;
  };

  active: boolean;

  simulation: any;

  messages: Message[];

  numPeople: number;

  messageTick: any;

  data: Data;

  constructor(element) {
    this.size = {
      width: 1200,
      height: 600,
      flow: {
        width: 240,
        height: 60,
        logo: 35,
        sideGap: 40,
      },
      engineer: {
        radius: 8,
        position: 200,
      },
      software: {
        width: 24,
        position: 230,
      },
      message: {
        radius: 3,
        count: 10,
      },
    };

    this.element = element;
    this.svg = select(element).attr(
      'viewBox',
      `0 0 ${this.size.width} ${this.size.height}`
    );

    this.active = false;

    this.groups = {
      link: this.svg.append('g').attr('class', 'links'),
      messages: this.svg.append('g').attr('class', 'messages'),
      node: this.svg.append('g').attr('class', 'nodes'),
      softwares: this.svg.append('g').attr('class', 'softwares'),
      text: this.svg.append('g').attr('class', 'text'),
      flow: this.svg.append('g').attr('class', 'flow'),
    };

    this.svg
      .append('rect')
      .attr('class', 'fade')
      .attr('x', '0')
      .attr('y', '0')
      .attr('fill', 'url(#gradient)');

    this.svg
      .append('rect')
      .attr('class', 'fade')
      .attr('x', '0')
      .attr('y', this.size.height - 30)
      .attr('fill', 'url(#gradientReverse)');

    this.messages = [];

    this.simulation = simulation
      .forceSimulation()
      .force(
        'link',
        simulation
          .forceLink()
          .id((d) => d.id)
          .distance((d) => {
            if (
              d.source.type === NodeType.ENGINEER &&
              d.target.type === NodeType.ENGINEER
            ) {
              if (d.target.id - d.source.id === 2) {
                return this.size.engineer.position * 2;
              }
              return this.size.engineer.position * Math.sqrt(2);
            }
            if (
              d.source.type === NodeType.ENGINEER &&
              d.target.type === NodeType.SOFTWARE
            ) {
              return 30;
            }
            if (
              d.source.type === NodeType.FLOW &&
              d.target.type === NodeType.ENGINEER
            ) {
              return 100;
            }
            if (
              d.source.type === NodeType.FLOW &&
              d.target.type === NodeType.SOFTWARE
            ) {
              return 80;
            }
            return 30;
          })
          .strength((d) => {
            if (
              d.source.type === NodeType.ENGINEER &&
              d.target.type === NodeType.ENGINEER
            ) {
              return 1;
            }
            if (
              d.source.type === NodeType.ENGINEER &&
              d.target.type === NodeType.SOFTWARE
            ) {
              return 1;
            }
            if (
              d.source.type === NodeType.FLOW &&
              d.target.type === NodeType.ENGINEER
            ) {
              return 1;
            }
            if (
              d.source.type === NodeType.FLOW &&
              d.target.type === NodeType.SOFTWARE
            ) {
              return 4;
            }
            return 0;
          })
      )
      .force('charge', simulation.forceManyBody().strength(-300))
      .force(
        'center',
        simulation.forceCenter(this.size.width / 2, this.size.height / 2)
      )
      .force(
        'y',
        simulation
          .forceY((d) => {
            if (d.type === NodeType.ENGINEER && d.id !== 'a' && this.active) {
              return 0;
            }
            if (d.type === NodeType.ENGINEER && d.id === 'a' && this.active) {
              return this.size.height / 2;
            }
            if (d.type === NodeType.SOFTWARE && this.active) {
              return this.size.height;
            }
            if (d.type === NodeType.FLOW) {
              return this.size.height / 2;
            }
            return null;
          })
          .strength((d) => {
            if (d.type === NodeType.ENGINEER && d.id !== 'a' && this.active) {
              return 1;
            }
            if (d.type === NodeType.ENGINEER && d.id === 'a' && this.active) {
              return 3;
            }
            if (d.type === NodeType.SOFTWARE && this.active) {
              return 1;
            }
            if (d.type === NodeType.FLOW && this.active) {
              return 2;
            }
            return 0;
          })
      )
      .force(
        'x',
        simulation
          .forceX((d) => {
            if (d.type === NodeType.ENGINEER && d.id !== 'a' && this.active) {
              const start = this.size.width / 2 - this.size.flow.width;
              const part = (this.size.flow.width * 2) / (this.numPeople - 2);
              return start + part * d.order;
            }
            if (d.id === 'a' && this.active) {
              return this.size.width / 2 + this.size.height / 2;
            }
            if (d.type === NodeType.SOFTWARE && this.active) {
              const start = this.size.width / 2 - this.size.flow.width / 2;
              const part = this.size.flow.width / 5;
              return start + part * d.order;
            }
            return 0;
          })
          .strength((d) => {
            if (d.type === NodeType.ENGINEER && d.id !== 'a' && this.active) {
              return 2;
            }
            if (d.id === 'a' && this.active) {
              return 1;
            }
            if (d.type === NodeType.SOFTWARE && this.active) {
              return 5;
            }
            return 0;
          })
      )
      .force(
        'radial',
        simulation
          .forceRadial(
            (d) => {
              switch (d.type) {
                case NodeType.ENGINEER:
                  return this.size.engineer.position;
                case NodeType.SOFTWARE:
                  return this.size.software.position;
                case NodeType.FLOW:
                  return 0;
                default:
                  return 0;
              }
            },
            this.size.width / 2,
            this.size.height / 2
          )
          .strength(1)
      )
      .force(
        'collide',
        simulation.forceCollide((d) => {
          switch (d.type) {
            case NodeType.ENGINEER:
              return this.active ? 40 : 30;
            case NodeType.SOFTWARE:
              return 20;
            case NodeType.FLOW:
              return 20;
            default:
              return 0;
          }
        })
      );

    this.update = this.update.bind(this);
    this.ticked = this.ticked.bind(this);
    this.dragStarted = this.dragStarted.bind(this);
    this.dragged = this.dragged.bind(this);
    this.dragEnded = this.dragEnded.bind(this);
    this.resetMessages = this.resetMessages.bind(this);
    this.animateMessages = this.animateMessages.bind(this);
  }

  setData(active: boolean, numPeople = 4) {
    const newData = getNewData(numPeople, active);
    if (this.data) {
      // add nodes
      newData.nodes.forEach((node: NodeChart) => {
        if (!this.data.nodes.some((e: NodeChart) => e.id === node.id)) {
          this.data.nodes.push(node);
        }
      });

      // add nodes to a queue
      const idsToRemove = [];
      this.data.nodes.forEach((node: NodeChart) => {
        if (!newData.nodes.some((e: NodeChart) => e.id === node.id)) {
          idsToRemove.push(node.id);
        }
      });
      // remove nodes from queue
      idsToRemove.forEach((id) => {
        const index = this.data.nodes.findIndex((e: NodeChart) => e.id === id);
        this.data.nodes.splice(index, 1);
      });

      // update links
      this.data.links = newData.links;
    } else {
      this.data = newData;
    }
    this.numPeople = numPeople;
    this.update(active);
  }

  update(active: boolean) {
    this.active = active;

    // reset alpha
    this.simulation.alpha(0.15).restart();

    // reset elements
    this.svg.selectAll('line').remove();
    this.svg.selectAll('svg').remove();
    this.svg.selectAll('circle').remove();
    this.svg.selectAll('text').remove();
    this.svg.selectAll('image').remove();

    // Render links
    this.groups.link
      .selectAll('line')
      .data(this.data.links)
      .enter()
      .append('line');

    // Render nodes
    this.groups.node
      .attr('class', `nodes`)
      .selectAll('circle')
      .data(
        this.data.nodes.filter((e: NodeChart) => e.type === NodeType.ENGINEER)
      )
      .enter()
      .append('circle')
      .attr('r', this.size.engineer.radius)
      .attr('class', 'draggable');

    // Render softwares
    this.groups.softwares
      .attr('class', 'softwares')
      .selectAll('image')
      .data(
        this.data.nodes.filter((e: NodeChart) => e.type === NodeType.SOFTWARE)
      )
      .enter()
      .append('image')
      .attr('width', this.size.software.width)
      .attr('height', this.size.software.width)
      .attr('href', (d) => d.image)
      .attr('class', 'draggable');

    // Render Flow node
    const flowNode = this.groups.flow
      .attr('class', `flow${active ? ' active' : ''}`)
      .selectAll('svg')
      .data(this.data.nodes.filter((e: NodeChart) => e.type === NodeType.FLOW))
      .enter()
      .append('svg')
      .attr('width', this.size.flow.width)
      .attr('height', this.size.flow.height)
      .attr('viewBox', `0 0 ${this.size.flow.width} ${this.size.flow.height}`)
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('class', 'draggable');

    flowNode
      .append('rect')
      .attr('width', this.size.flow.width - 2)
      .attr('height', this.size.flow.height - 2)
      .attr('x', 1)
      .attr('y', 1)
      .attr('rx', 5)
      .attr('fill', '#EAEAEA')
      .attr('stroke', '#ABA8A8')
      .attr('stroke-width', 1);

    flowNode
      .append('image')
      .attr('width', this.size.flow.logo)
      .attr('height', this.size.flow.logo)
      .attr('x', this.size.flow.width / 2 - this.size.flow.logo / 2)
      .attr('y', this.size.flow.height / 2 - this.size.flow.logo / 2)
      .attr('href', FlowLogo);

    this.svg
      .selectAll('.draggable')
      .call(
        drag()
          .on('start', this.dragStarted)
          .on('drag', this.dragged)
          .on('end', this.dragEnded)
      );

    const textNode = this.groups.text
      .selectAll('svg')
      .data(
        this.data.nodes.filter((e: NodeChart) => e.type === NodeType.ENGINEER)
      )
      .enter()
      .append('svg')
      .attr('width', '100')
      .attr('height', '50')
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .append('text')
      .attr('transform', 'translate(50)');

    textNode
      .selectAll('tspan')
      .data((d: Node) => d.name.split(' '))
      .enter()
      .append('tspan')
      .text((d: Node) => d)
      .attr('text-anchor', 'middle')
      .attr('x', '0')
      .attr('dy', '1.2em');

    this.simulation.nodes(this.data.nodes).on('tick', this.ticked);
    this.simulation.force('link').links(this.data.links);

    this.resetMessages();
  }

  ticked() {
    this.groups.link
      .selectAll('line')
      .attr('x1', (d: LinkChart) => {
        if (d.target.type === NodeType.SOFTWARE && this.active) {
          const start = d.source.x - this.size.flow.width / 2;
          const space = (this.size.flow.width - this.size.flow.sideGap * 2) / 5;
          return start + this.size.flow.sideGap + space * d.target.order;
        }
        if (
          d.target.type === NodeType.ENGINEER &&
          d.target.id !== 'a' &&
          this.active
        ) {
          const start = d.source.x - this.size.flow.width / 2;
          const space =
            (this.size.flow.width - this.size.flow.sideGap * 2) /
            (this.numPeople - 2);
          return start + this.size.flow.sideGap + space * d.target.order;
        }
        if (
          d.target.type === NodeType.ENGINEER &&
          d.target.id === 'a' &&
          this.active
        ) {
          return d.source.x + this.size.flow.width / 2 - 1;
        }
        return d.source.x;
      })
      .attr('y1', (d: LinkChart) => {
        if (d.target.type === NodeType.SOFTWARE && this.active) {
          return d.source.y + this.size.flow.height / 2 - 1;
        }
        if (
          d.target.type === NodeType.ENGINEER &&
          d.target.id !== 'a' &&
          this.active
        ) {
          return d.source.y - this.size.flow.height / 2 + 1;
        }
        return d.source.y;
      })
      .attr('x2', (d: LinkChart) => d.target.x)
      .attr('y2', (d: LinkChart) => d.target.y);
    this.groups.node
      .selectAll('circle')
      .attr('cx', (d: NodeChart) => d.x)
      .attr('cy', (d: NodeChart) => d.y);
    this.groups.softwares
      .selectAll('image')
      .attr('x', (d: NodeChart) => d.x - this.size.software.width / 2)
      .attr('y', (d: NodeChart) => d.y - this.size.software.width / 2);
    this.groups.flow
      .selectAll('svg')
      .attr('x', (d: NodeChart) => d.x - this.size.flow.width / 2)
      .attr('y', (d: NodeChart) => d.y - this.size.flow.height / 2);
    this.groups.text
      .selectAll('svg')
      .attr('x', (d: NodeChart) => d.x - 50)
      .attr('y', (d: NodeChart) => d.y + 10);
  }

  resetMessages() {
    for (let i = 0; i < this.size.message.count; i += 1) {
      const newMessage = new Message(this);
      this.messages.push(newMessage);
    }
    if (!this.messageTick) {
      this.animateMessages();
    }
  }

  animateMessages() {
    this.messages.forEach((message: Message) => {
      message.tick();
    });
    this.messageTick = window.requestAnimationFrame(this.animateMessages);
  }

  dragStarted(event: any, d: NodeChart) {
    if (!event.active) this.simulation.alphaTarget(0.1).restart();
    d.fy = d.y;
    d.fx = d.x;
  }

  dragged(event: any, d: NodeChart) {
    d.fx = event.x;
    d.fy = event.y;
  }

  dragEnded(event: any, d: NodeChart) {
    if (!event.active) this.simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  }

  destroy() {
    this.element.innerHTML = '';
    window.cancelAnimationFrame(this.messageTick);
  }
}

type GraphNetworkProps = {
  copy: Record<string, string>;
};

function GraphNetwork({ copy }: GraphNetworkProps) {
  const timer = useRef(null);
  const ref = useRef(null);
  const chart = useRef<Chart>();
  const [numPeople, setNumPeople] = useState(4);
  const [flowView, setFlowView] = useState(false);

  // should only run at startup
  useEffect(() => {
    const current = new Chart(ref.current);
    chart.current = current;

    return function unbind() {
      current.destroy();
    };
  }, []);

  // listen to updates of data
  useEffect(() => {
    if (chart.current) {
      chart.current.setData(flowView, numPeople);
    }
  }, [numPeople, flowView]);

  /*
  useEffect(() => {
    if (inView) {
      timer.current = setTimeout(() => setFlowView(true), 5000);
    }
  }, [inView, timer]);
  */

  return (
    <Container>
      <Graph>
        <ToggleSwitch
          onChange={() => {
            setFlowView((s) => !s);
            if (timer.current) {
              clearTimeout(timer.current);
            }
          }}
          value={flowView}
          labels={[copy.labelToday, copy.labelWithFlow]}
        />
        <SvgWrapper>
          <SVG ref={ref} preserveAspectRatio="xMidYMid meet">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop
                  offset="0%"
                  style={{ stopColor: '#ffffff', stopOpacity: '1' }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: '#ffffff', stopOpacity: '0' }}
                />
              </linearGradient>
              <linearGradient
                id="gradientReverse"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop
                  offset="0%"
                  style={{ stopColor: '#ffffff', stopOpacity: '0' }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: '#ffffff', stopOpacity: '100%' }}
                />
              </linearGradient>
            </defs>
          </SVG>
        </SvgWrapper>
        <Options>
          Team size:<span>{numPeople} people</span>
        </Options>
        <InputSlider
          min={3}
          max={10}
          step={1}
          value={numPeople}
          onChange={(e) => setNumPeople(e)}
        />
      </Graph>
    </Container>
  );
}

export default memo(GraphNetwork);

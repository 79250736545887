import React, { memo } from 'react';

import styled from 'styled-components';
import BREAKPOINTS from './breakpoints';
import COLORS from './colors';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  ${BREAKPOINTS.max.small`
    flex-direction: column;
    align-items:center;
  `}
`;

type SwitchContainerProps = {
  $backgroundColor: string;
  $borderColor: string;
};

const SwitchContainer = styled.div<SwitchContainerProps>`
  position: relative;
  width: 36px;
  height: 24px;
  background: ${(props) => props.$backgroundColor};
  border: 1px solid ${(props) => props.$borderColor};
  border-radius: 3px;
  cursor: pointer;
  order: 0;
  ${BREAKPOINTS.max.small`
    order:0;
    margin-bottom: 6px;
  `}
`;

type LabelProps = {
  $active: boolean;
};
const Label = styled.span<LabelProps>`
  padding: 0 12px;
  color: ${(props) =>
    props.$active ? COLORS.shades.s300.css : COLORS.shades.s200.css};
  cursor: pointer;
  margin: 3px 0;
  ${BREAKPOINTS.max.small`
    order:2;
  `}
`;

type ToggleSwitchProps = {
  $isOn: boolean;
  $color: string;
};

const ToggleSwitch = styled.div<ToggleSwitchProps>`
  position: absolute;
  ${(props) => (props.$isOn ? 'right' : 'left')}: 2px;
  left: 0px;
  top: 2px;
  width: 18px;
  height: 18px;
  transform: translateX(${(props) => (props.$isOn ? 14 : 2)}px);
  transition: transform 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8),
    background 0.2s ease;
  border-radius: 2px;
  background: ${(props) => props.$color};
`;

type ControlToggleProps = {
  value: boolean;
  onChange: (v: boolean) => void;
  color?: string;
  borderColor?: string;
  backgroundColor?: string;
  labels?: string[];
};
function ControlToggle({
  value,
  onChange,
  color = COLORS.brand.regular.css,
  borderColor = COLORS.shades.s200.css,
  backgroundColor = COLORS.white.css,
  labels = [],
  ...props
}: ControlToggleProps) {
  return (
    <Container
      onClick={(evt) => {
        onChange(!value);
        evt.stopPropagation();
      }}
      onDoubleClick={(evt) => {
        evt.stopPropagation();
      }}
      {...props}
    >
      {labels.length === 2 && <Label $active={!value}>{labels[0]}</Label>}
      <SwitchContainer
        $borderColor={borderColor}
        $backgroundColor={backgroundColor}
      >
        <ToggleSwitch $color={color} $isOn={value} />
      </SwitchContainer>
      {labels.length === 2 && <Label $active={value}>{labels[1]}</Label>}
    </Container>
  );
}

export default memo(ControlToggle);
